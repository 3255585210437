import React, { useState, useCallback, useEffect } from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { useEmblaCarousel } from 'embla-carousel/react';
import { useGesture } from 'react-use-gesture';
import { motion, useMotionValue, useSpring, AnimatePresence } from 'framer-motion';

// SVG
import ArrowRight from '../../../svg/arrow-r.svg';
import ArrowLeft from '../../../svg/arrow-l.svg';

import { MotionBlockWrapper } from '../motionBlockWrapper';

const CarouselImage = ({ image }) => {
  return (
    <div className="w-full md:w-1/3 pl-4" style={{ flex: '0 0 auto' }}>
      {image && image.asset && <Image {...image} />}
    </div>
  );
};

const cursorVars = {
  visible: {
    scale: 1,
    opacity: 1,
  },
  dragging: {
    scale: 0.5,
    opacity: 1,
  },
  hidden: {
    scale: 0.2,
    opacity: 0,
  },
};

const cursorTextVars = {
  visible: {
    opacity: 1,
  },
  dragging: {
    opacity: 0,
  },
  hidden: {
    opacity: 0,
  },
};

const ImageCarousel = ({ blockConfig, items }) => {
  // Embla Setup
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    dragFree: true,
    align: 'start',
    containScroll: true,
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  // Cursor Setup

  const [cusorActive, setCursorActive] = useState(false);
  const [isDrag, setDrag] = useState(false);

  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 40, stiffness: 300 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  const bind = useGesture({
    onHover: ({ hovering }) => setCursorActive(hovering),
    onDrag: ({ dragging }) => setDrag(dragging),
    onMove: ({ values }) => {
      cursorX.set(values[0] - 40);
      cursorY.set(values[1] - 40);
    },
  });

  return (
    <MotionBlockWrapper {...blockConfig} className="px-gutter overflow-hidden">
      <AnimatePresence>
        {cusorActive && (
          <motion.div
            style={{
              width: '80px',
              height: '80px',
              translateX: cursorXSpring,
              translateY: cursorYSpring,
            }}
            className="fixed left-0 top-0 rounded-full z-2000 pointer-events-none bg-oslo-green cursor-none flex items-center justify-center"
            variants={cursorVars}
            initial="hidden"
            animate={isDrag ? 'dragging' : 'visible'}
            exit="hidden"
          >
            <motion.span className="text-white font-sansMedium" variants={cursorTextVars}>
              Drag
            </motion.span>
          </motion.div>
        )}
      </AnimatePresence>
      <div ref={emblaRef} className="-ml-4 cursor-none" {...bind()}>
        <div className="flex w-full">
          {items.map((item) => (
            <CarouselImage {...item} />
          ))}
        </div>
      </div>
      <div className="flex py-4 justify-center md:py-10 md:justify-end">
        <nav className="grid grid-flow-col gap-x-3 mt-6 md:mt-0 md:gap-x-6">
          <button
            className={`w-16 focus:outline-none transition-opacity ${
              !prevBtnEnabled && 'opacity-40'
            }`}
            onClick={scrollPrev}
            disabled={!prevBtnEnabled}
          >
            <ArrowLeft />
          </button>
          <button
            className={`w-16 focus:outline-none transition-opacity ${
              !nextBtnEnabled && 'opacity-40'
            }`}
            onClick={scrollNext}
            disabled={!nextBtnEnabled}
          >
            <ArrowRight />
          </button>
        </nav>
      </div>
    </MotionBlockWrapper>
  );
};

export default ImageCarousel;

import React from "react"

import { useInView } from "react-intersection-observer"

import { BlockWrapper } from "../blockWrapper"
import { VideoModule } from "../../modules"

const VideoBlock = ({ blockConfig, video }) => {
  return (
    <BlockWrapper {...blockConfig}>
      <VideoModule {...video} />
    </BlockWrapper>
  )
}

export default VideoBlock

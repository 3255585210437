import React, { useState } from 'react';

import { useGesture } from 'react-use-gesture';
import { motion, useMotionValue, useSpring, AnimatePresence } from 'framer-motion';

import ArrowLeft from '../../svg/arrow-l.svg';
import ArrowRight from '../../svg/arrow-r.svg';

const cursorVars = {
  visible: {
    scale: 1,
    opacity: 1,
  },
  dragging: {
    scale: 0.5,
    opacity: 1,
  },
  hidden: {
    scale: 0.2,
    opacity: 0,
  },
};

export const ArrowCursor = ({ direction = 'left', ...rest }) => {
  const [cusorActive, setCursorActive] = useState(false);

  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 40, stiffness: 300 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  const bind = useGesture({
    onHover: ({ hovering }) => setCursorActive(hovering),
    onMove: ({ values }) => {
      cursorX.set(values[0] - 40);
      cursorY.set(values[1] - 40);
    },
  });

  return (
    <button {...rest} {...bind()}>
      <AnimatePresence>
        {cusorActive && (
          <motion.div
            style={{
              width: '80px',
              height: '80px',
              translateX: cursorXSpring,
              translateY: cursorYSpring,
            }}
            className="fixed left-0 top-0 pointer-events-none z-75 text-white flex items-center justify-center"
            variants={cursorVars}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            {direction === 'left' ? <ArrowLeft className="text-white" /> : <ArrowRight />}
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  );
};

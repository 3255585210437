import React from "react"

// Hooks
import { usePadTop, usePadBottom } from "../../hooks"

export const BlockWrapper = ({
  children,
  className,
  paddingTop,
  paddingBottom,
  slug,
}) => {
  const padTop = usePadTop(paddingTop)
  const padBottom = usePadBottom(paddingBottom)
  return (
    <section
      className={`${className} ${padTop} ${padBottom}`}
      id={slug && slug.current}
    >
      {children}
    </section>
  )
}

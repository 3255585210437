import React from 'react';
import PropTypes from 'prop-types';

import {
  TextBlock,
  ImageText,
  ImageBlock,
  VideoBlock,
  ImageSlider,
  ProjectsFeed,
  ImageCarousel,
  FeaturesBlock,
  ImageGrid,
  ContactBlock,
} from './blocks';

const BlockZone = ({ blocks }) => {
  const block = blocks.map((block) => {
    switch (block._type) {
      case 'textBlock':
        return <TextBlock {...block} />;
      case 'imageText':
        return <ImageText {...block} />;
      case 'imageBlock':
        return <ImageBlock {...block} />;
      case 'videoBlock':
        return <VideoBlock {...block} />;
      case 'imageSlider':
        return <ImageSlider {...block} />;
      case 'projectsFeed':
        return <ProjectsFeed {...block} />;
      case 'imageCarousel':
        return <ImageCarousel {...block} />;
      case 'featuresBlock':
        return <FeaturesBlock {...block} />;
      case 'imageGrid':
        return <ImageGrid {...block} />;
      case 'contactBlock':
        return <ContactBlock {...block} />;
      default:
        return null;
    }
  });
  return <div className="dev">{block}</div>;
};

BlockZone.propTypes = {
  blocks: PropTypes.array.isRequired,
};

export default BlockZone;

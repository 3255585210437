import React, { useEffect } from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import { BlockWrapper } from '../blockWrapper';

const gridVars = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 60 },
};

const GridImage = ({ columnSpan, image, parentCols, align }) => {
  const gridClasses = () => {
    switch (columnSpan) {
      case 1:
        return 'md:col-span-1';
      case 2:
        return 'md:col-span-2';
      case 3:
        return parentCols > 2 ? 'md:col-span-3' : 'md:col-span-2';
      default:
        return 'md:col-span-2';
    }
  };

  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.3 });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      className={`${gridClasses()} ${
        align === 'bottom' ? 'md:self-end' : align === 'center' ? 'md:self-center' : 'md:self-start'
      }`}
      ref={ref}
      variants={gridVars}
      animate={controls}
      initial="hidden"
      transition={{ duration: 1, ease: 'easeOut', delay: 0.5 }}
    >
      <div className="w-full">
        {image && image.asset && <Image {...image} className="w-full" />}
      </div>
    </motion.div>
  );
};

const ImageGrid = ({ items, columns, blockConfig }) => {
  return (
    <BlockWrapper {...blockConfig} className="px-gutter">
      <div
        className={`grid grid-cols-1 gap-6 md:gap-12 ${
          columns > 2 ? 'md:grid-cols-3' : 'md:grid-cols-2'
        }`}
      >
        {items.map((item) => (
          <GridImage {...item} parentCols={columns} />
        ))}
      </div>
    </BlockWrapper>
  );
};

export default ImageGrid;

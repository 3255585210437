import React from 'react';
import { Link } from 'gatsby';
import { useLatestProjects } from '../../../GraphQl/useLatestProjects';

import { MotionBlockWrapper } from '../motionBlockWrapper';

const ProjectsRow = ({ title, subheading, slug }) => {
  return (
    <Link to={`/projects/${slug && slug.current}`} className="py-6">
      <h3 className="text-3xl mb-2 lg:text-4xl lg:mb-3">{title}</h3>
      <p className="text-lg">{subheading}</p>
    </Link>
  );
};

const ProjectsFeed = ({ title, blockConfig, scopedProjects }) => {
  const projects = useLatestProjects();
  return (
    <MotionBlockWrapper {...blockConfig} className="grid grid-cols-14">
      <div className="col-start-2 col-end-14 md:col-end-4">
        <h2 className="font-sansMedium text-xl pt-6">{title}</h2>
      </div>
      <div className="col-start-2 col-end-14 md:col-start-6 grid divide-y divide-current divide-opacity-40">
        {scopedProjects ? (
          <>
            {scopedProjects.map(({ node }) => (
              <ProjectsRow {...node} />
            ))}
          </>
        ) : (
          <>
            {projects.map(({ node }) => (
              <ProjectsRow {...node} />
            ))}
          </>
        )}
      </div>
    </MotionBlockWrapper>
  );
};

export default ProjectsFeed;

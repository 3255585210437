import React from 'react';
import Image from 'gatsby-plugin-sanity-image';

import { MotionBlockWrapper } from '../motionBlockWrapper';

const ImageBlock = ({ blockConfig, image, layout }) => {
  const layoutClasses =
    layout === 'left'
      ? 'col-start-2 col-end-14 md:col-start-2 md:col-end-12'
      : layout === 'right'
      ? 'col-start-2 col-end-14 md:col-start-4 md:col-end-14'
      : layout === 'full'
      ? 'col-start-1 col-end-15'
      : layout === 'drop-left'
      ? 'col-start-2 col-end-14 md:col-end-10 2xl:col-end-9'
      : layout === 'drop-right'
      ? 'col-start-2 col-end-14 md:col-start-6 2xl:md-start-7'
      : 'col-start-1 col-end-15 md:col-start-2 md:col-end-14';
  return (
    <MotionBlockWrapper {...blockConfig} className="grid grid-cols-14">
      <div className={`${layoutClasses}`}>
        <div className="w-full">{image && <Image {...image} className="w-full" />}</div>
      </div>
    </MotionBlockWrapper>
  );
};

export default ImageBlock;

import React from 'react';
import { motion } from 'framer-motion';
import { useLocation } from '@reach/router';
import { Formik, Form, Field, ErrorMessage } from 'formik';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export const ContactForm = () => {
  const { pathname } = useLocation();

  const inputClasses = 'border-b-2 w-full border-current py-3';

  return (
    <div>
      <Formik
        initialValues={{
          fullName: '',
          companyName: '',
          contactMethod: 'Email',
          email: '',
          phone: '',
          message: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.fullName) {
            errors.fullName = 'Full Name is Required';
          }
          if (values.contactMethod === 'Email') {
            if (!values.email) {
              errors.email = 'Email address is Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          fetch(pathname || '/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
              'form-name': `contact-form`,
              ...values,
            }),
          })
            .then(() => {
              setSubmitting(false);
              setStatus('success');
            })
            .catch((error) => alert(error));
        }}
      >
        {({
          onChange,
          isSubmitting,
          status,
          values,
          /* and other goodies */
        }) => (
          <Form
            className="col-start-2 col-end-14 lg:col-start-3 lg:col-end-13"
            name="contact-form"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            subject="Website Enquiry"
          >
            {/* Hidden Fields for netlify */}
            <input type="hidden" name="form-name" value="contact-form" />
            <p hidden>
              <label>
                <input name="bot-field" onChange={onChange} />
              </label>
            </p>
            {status === 'success' ? (
              <div className="col-span-full text-left">
                <motion.h2
                  className="font-display text-2xl"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                >
                  Thank you we will be in touch.
                </motion.h2>
              </div>
            ) : (
              <>
                <div className="grid gap-y-6">
                  <div>
                    <Field
                      type="text"
                      name="fullName"
                      className={inputClasses}
                      placeholder="Full Name"
                    />
                    <ErrorMessage name="fullName" className="text-sm my-2" component="div" />
                  </div>
                  <Field
                    type="text"
                    name="companyName"
                    className={inputClasses}
                    placeholder="Company Name"
                  />
                  <div>
                    <Field type="email" name="email" className={inputClasses} placeholder="Email" />
                    <ErrorMessage name="email" className="text-sm my-2" component="div" />
                  </div>
                  <Field
                    type="text"
                    name="phone"
                    className={inputClasses}
                    placeholder="Phone Number"
                  />
                  <button
                    className="self-end justify-self-end underline font-sansMedium text-lg"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

import React from 'react';

import { MotionBlockWrapper } from '../motionBlockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const TextBlock = ({ text, centerText, layout, blockConfig }) => {
  const layoutClasses =
    layout === 'left'
      ? 'lg:col-end-11'
      : layout === 'right'
      ? 'lg:col-start-6'
      : layout === 'drop-left'
      ? 'lg:col-end-7'
      : layout === 'drop-right' && 'lg:col-start-8';

  return (
    <MotionBlockWrapper className={`grid grid-cols-14`} {...blockConfig}>
      <PortableTextBlock
        text={text}
        className={`prose prose-lg xl:prose-xl max-w-none col-start-2 col-end-14 ${layoutClasses} ${
          centerText && 'text-center'
        }`}
      />
    </MotionBlockWrapper>
  );
};

export default TextBlock;

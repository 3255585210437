import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useLatestProjects = () => {
  const { allSanityProject } = useStaticQuery(graphql`
    query {
      allSanityProject(limit: 4) {
        edges {
          node {
            title
            subheading
            slug {
              current
            }
          }
        }
      }
    }
  `);
  return (allSanityProject && allSanityProject.edges) || [];
};

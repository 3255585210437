import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

// Components
import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';
import { ContactForm } from '../../forms/contactForm';

const ContactBlock = ({ blockConfig, contactDetails }) => {
  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14 lg:min-h-screen">
      <div className="col-start-1 col-end-7 bg-oslo-green row-start-1 z-20 hidden lg:block" />
      {/* Block Text */}
      <div className="col-start-1 col-end-15 self-center grid grid-cols-14 gap-y-16 md:gap-y-20 lg:row-start-1">
        <div className="col-start-1 col-end-15 bg-oslo-green row-start-1 z-20 lg:hidden" />
        <div className="col-start-1 col-end-15 row-start-1 relative z-30 text-oslo-blue px-gutter py-40 lg:col-end-7 lg:py-0">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: 'easeOut', delay: 0.5 }}
          >
            <PortableTextBlock text={contactDetails} className="prose prose-lg xl:prose-xl" />
          </motion.div>
        </div>
        {/* Block Form */}
        <div className="col-start-1 col-end-15 px-gutter lg:col-start-8 lg:px-0 lg:pr-gutter pb-16 lg:pb-0">
          <motion.div
            className="w-full"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: 'easeOut', delay: 0.8 }}
          >
            <div className="prose prose-lg xl:prose-xl mb-10">
              <h2>Contact Form</h2>
            </div>
            <div className="w-full">
              <ContactForm />
            </div>
          </motion.div>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default ContactBlock;

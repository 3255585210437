import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
// Hooks
import { usePadTop, usePadBottom } from '../../hooks';

const blockVars = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 60 },
};

export const MotionBlockWrapper = ({ children, className, paddingTop, paddingBottom, slug }) => {
  const padTop = usePadTop(paddingTop);
  const padBottom = usePadBottom(paddingBottom);

  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.3 });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.section
      className={`${className} ${padTop} ${padBottom}`}
      id={slug && slug.current}
      variants={blockVars}
      animate={controls}
      initial="hidden"
      ref={ref}
      transition={{ duration: 1, ease: 'easeOut', delay: 0.5 }}
    >
      {children}
    </motion.section>
  );
};

import React, { useState } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import { wrap } from '@popmotion/popcorn';
import { motion, AnimatePresence } from 'framer-motion';

// Hooks
import { useSanityPath } from '../../../hooks';

// SVG
import ArrowRight from '../../../svg/arrow-r.svg';
import ArrowLeft from '../../../svg/arrow-l.svg';

// Components
import { MotionBlockWrapper } from '../motionBlockWrapper';
import { ArrowCursor } from '../../ui/arrowCursor';

const imageVariants = {
  enter: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: direction > 0 ? -1000 : 1000,
    opacity: 0,
  }),
};

const SlideImage = ({ image, page, ...rest }) => {
  const path = useSanityPath(page);

  return (
    <motion.div
      {...rest}
      initial="enter"
      animate="center"
      exit="exit"
      variants={imageVariants}
      className="absolute top-0 left-0"
      transition={{ duration: 0.5, ease: 'easeOut' }}
    >
      <Link to={path} className="h-full w-full top-0 left-0">
        {image && image.asset && (
          <Image
            {...image}
            className="h-full w-full object-cover top-0 left-0"
            style={{ position: 'absolute' }}
          />
        )}
      </Link>
    </motion.div>
  );
};

const captionVariants = {
  enter: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  exit: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: -1,
    },
  },
};

const captionInnerVars = {
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
  exit: {
    y: 20,
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
};

const SlideCaption = ({ title, caption, page, ...rest }) => {
  const path = useSanityPath(page);
  return (
    <motion.div
      className="py-7"
      initial="exit"
      animate="enter"
      exit="exit"
      variants={captionVariants}
      {...rest}
    >
      <Link to={path}>
        {title && (
          <motion.h2 className="text-3xl md:text-4xl mb-2" variants={captionInnerVars}>
            {title}
          </motion.h2>
        )}
        {caption && (
          <motion.p variants={captionInnerVars} className="text-lg">
            {caption}
          </motion.p>
        )}
      </Link>
    </motion.div>
  );
};

const ImageSlider = ({ blockConfig, items }) => {
  const [[pageIndex, direction], setPageIndex] = useState([0, 0]);
  const wrappedIndex = wrap(0, items.length, pageIndex);

  const paginate = (direction) => {
    setPageIndex([pageIndex + direction, direction]);
  };

  return (
    <MotionBlockWrapper {...blockConfig} className="w-full overflow-hidden md:px-gutter">
      <div className="aspect-w-16 aspect-h-9 relative">
        <ArrowCursor
          direction="left"
          className="absolute top-0 left-0 z-2000 h-full w-1/3 focus:outline-none cursor-none hidden lg:flex"
          onClick={() => paginate(-1)}
        />
        <AnimatePresence custom={direction} initial={false}>
          <SlideImage {...items[wrappedIndex]} key={pageIndex} custom={direction} />
        </AnimatePresence>
        <ArrowCursor
          direction="right"
          className="absolute top-0 right-0 left-auto z-2000 h-full w-1/3 focus:outline-none cursor-none hidden lg:flex"
          onClick={() => paginate(+1)}
        />
      </div>
      <div className="flex flex-col justify-between px-gutter md:px-0 md:flex-row">
        <AnimatePresence exitBeforeEnter>
          <SlideCaption {...items[wrappedIndex]} key={pageIndex} />
        </AnimatePresence>
        <nav className="grid grid-flow-col gap-x-1 mt-6 md:mt-0 md:gap-x-6">
          <button className="w-16 focus:outline-none" onClick={() => paginate(-1)}>
            <ArrowLeft />
          </button>
          <button className="w-16 focus:outline-none" onClick={() => paginate(+1)}>
            <ArrowRight />
          </button>
        </nav>
      </div>
    </MotionBlockWrapper>
  );
};

export default ImageSlider;

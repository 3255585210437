import React from 'react';
import Image from 'gatsby-plugin-sanity-image';

import { MotionBlockWrapper } from '../motionBlockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const ImageText = ({ text, image, layout, blockConfig }) => {
  const imageClasses =
    layout === 'left' ? 'md:col-end-8 xl:col-end-8' : 'md:col-start-8 xl:col-start-8';
  const textClasses = layout === 'left' ? 'md:col-start-9' : 'md:col-end-7';
  return (
    <MotionBlockWrapper className="grid grid-cols-14" {...blockConfig}>
      <div className={`col-start-2 col-end-14 self-center md:row-start-1 ${imageClasses}`}>
        <div>{image && <Image {...image} className="w-full" />}</div>
      </div>
      <div
        className={`prose prose-xl max-w-none col-start-2 col-end-14 md:row-start-1 self-end ${textClasses}`}
      >
        <PortableTextBlock text={text} />
      </div>
    </MotionBlockWrapper>
  );
};

export default ImageText;

import React from 'react';

import { MotionBlockWrapper } from '../motionBlockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const Feature = ({ text }) => {
  return (
    <div>
      <PortableTextBlock text={text} className="prose" />
    </div>
  );
};

const FeaturesBlock = ({ blockConfig, title, layout, items }) => {
  const layoutClasses =
    layout === 'left' ? 'lg:col-end-11' : layout === 'right' && 'lg:col-start-5';

  return (
    <MotionBlockWrapper {...blockConfig} className="grid grid-cols-14">
      <div className={`col-start-2 col-end-14 ${layoutClasses}`}>
        <h2 className="text-2xl flex border-b border-oslo-green w-full pb-4 mb-10 xl:mb-11 xl:text-3xl">
          {title}
        </h2>
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-10 lg:grid-cols-3 lg:mt-16 border-oslo-green">
          {items.map((item) => (
            <Feature {...item} />
          ))}
        </div>
      </div>
    </MotionBlockWrapper>
  );
};

export default FeaturesBlock;
